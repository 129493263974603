import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useGenerateReportMutation } from 'src/graphql/generated';
import { getStripeKey } from '../../api/stripe-router';
import ApplePay from '../../components/payment/ApplePay';
import CardDetails from '../../components/payment/CardDetails';
import ConfirmVehicle from '../../components/payment/ConfirmVehicle';
import EnterYourDetails from '../../components/payment/EnterYourDetails';
import OrderSummary from '../../components/payment/OrderSummary';
import LockIcon from '../../icons/LockIcon';
import { isPpsrServiceDisabled } from '../../util/time';

export default function PaymentPage() {
  const [rego, setRego] = useState<string | null>(null);
  const [vin, setVin] = useState<string | null>(null);
  const [state, setState] = useState<string>('');
  const [vehicleTitle, setVehicleTitle] = useState<string>('');
  const [valuationId, setValuationId] = useState<string>('');

  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [postcode, setPostcode] = useState<string>('');
  const [reason, setReason] = useState<string>('');

  const [displayStepTwo, setDisplayStepTwo] = useState<string>('');
  const [displayStepThree, setDisplayStepThree] = useState<string>('');
  const [readTerms, setReadTerms] = useState(false);

  const [stripePromise, setStripePromise] = useState<any>(null);
  const [paymentError, setPaymentError] = useState<string | null>(null);
  const [paymentSuccess, setPaymentSuccess] = useState<boolean>(false);
  const [paymentProcessing, setPaymentProcessing] = useState<boolean>(false);
  const [reportUrl, setReportUrl] = useState<string>();

  const [ppsrDisabled, setPpsrDisabled] = useState<boolean>(false);

  const { state: queryData } = useLocation();

  const redirect = useNavigate();

  const [generateReport] = useGenerateReportMutation();

  const getReport = async (): Promise<void> => {
    try {
      const { data } = await generateReport({
        variables: {
          input: {
            valuation_id: valuationId,
            rego,
            vin,
            state,
            first_name: firstName,
            last_name: lastName,
            email,
            number: phoneNumber,
            post_code: postcode,
            reason,
          },
        },
      });

      if (!data?.generate_report.url) {
        throw new Error('Failed to generate report.');
      }

      setReportUrl(data.generate_report.url);
    } catch (error: any) {
      setPaymentError(
        'Payment was processed but report generation failed. A member of our team will be in touch to help resolve the issue.'
      );
      setPaymentProcessing(false);
      throw error;
    }
  };

  useEffect(() => {
    getStripeKey()
      .then(data => {
        setStripePromise(loadStripe(data));
      })
      .catch((err: any) =>
        console.log(`An error occured: ${err.message || 'unknown error'}`)
      );
  }, []);

  useEffect(() => {
    if (queryData) {
      setRego(queryData.rego);
      setVin(queryData.vin);
      setState(queryData.state);
      setVehicleTitle(queryData.vehicle_title);
      setValuationId(queryData.valuation_id);
    }
  }, [queryData]);

  useEffect(() => {
    if (paymentSuccess && reportUrl) {
      redirect('/success', {
        state: reportUrl,
      });
    }
  }, [paymentSuccess, reportUrl]);

  useEffect(() => {
    const checkTimeWindow = () => {
      const newPpsrDisabled = isPpsrServiceDisabled();
      if (newPpsrDisabled !== ppsrDisabled) {
        setPpsrDisabled(newPpsrDisabled);
      }
    };

    const intervalId = setInterval(checkTimeWindow, 1000 * 60);

    checkTimeWindow();

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div>
      <nav className='flex flex-row justify-between'>
        <Link to='/' className='flex items-center my-auto p-5'>
          <img
            src={require('../../icons/logo.png')}
            className='h-6 lg:h-8 mr-3'
            alt='car-analysis-logo'
          />
        </Link>
        <div className='flex flex-row justify-center space-x-2 p-6'>
          <div className='my-auto pb-0.5'>
            <LockIcon />
          </div>
          <div className='my-auto text-p1 md:text-p2'>Secure Checkout</div>
        </div>
      </nav>
      <div className='bg-grey-dark h-px' />
      <div className='flex flex-col lg:flex-row w-5/6 lg:w-4/5 2xl:w-3/4 mx-auto my-4 justify-between lg:my-10 lg:space-x-20'>
        <div className='lg:w-1/2'>
          <ConfirmVehicle
            displayStepTwo={displayStepTwo}
            setDisplayStepTwo={setDisplayStepTwo}
            displayStepThree={displayStepThree}
            setDisplayStepThree={setDisplayStepThree}
            rego={rego}
            vin={vin}
            state={state}
            vehicleTitle={vehicleTitle}
            odometer={queryData.odometer || '0'}
          />
          <div className='h-px bg-grey-dark' />
          <div className='my-4 text-sub1 lg:text-h2 font-semibold lg:font-regular'>
            2. Enter Your Details
          </div>
          {displayStepTwo && (
            <EnterYourDetails
              displayStepThree={displayStepThree}
              setDisplayStepThree={setDisplayStepThree}
              firstName={firstName}
              setFirstName={setFirstName}
              lastName={lastName}
              setLastName={setLastName}
              email={email}
              setEmail={setEmail}
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              postcode={postcode}
              setPostcode={setPostcode}
              reason={reason}
              setReason={setReason}
            />
          )}
          <div className='h-px bg-grey-dark' />
          <div className='my-4 text-sub1 lg:text-h2 font-semibold lg:font-regular'>
            3. Payment
          </div>
          {displayStepTwo && displayStepThree && (
            <div>
              <Elements stripe={stripePromise}>
                <ApplePay
                  paymentError={paymentError}
                  setPaymentError={setPaymentError}
                  setPaymentSuccess={setPaymentSuccess}
                  setPaymentProcessing={setPaymentProcessing}
                  getReport={getReport}
                  ppsrDisabled={ppsrDisabled}
                  readTerms={readTerms}
                />
                <CardDetails
                  paymentError={paymentError}
                  setPaymentError={setPaymentError}
                  setPaymentSuccess={setPaymentSuccess}
                  paymentProcessing={paymentProcessing}
                  setPaymentProcessing={setPaymentProcessing}
                  getReport={getReport}
                  readTerms={readTerms}
                />
              </Elements>
              <div className='flex items-center px-2 py-2'>
                <input
                  id='terms-checkbox-input'
                  type='checkbox'
                  checked={readTerms}
                  className='w-7 h-4 text-blue-600 rounded'
                  onClick={() => setReadTerms(!readTerms)}
                />
                <label
                  htmlFor='terms-checkbox-label'
                  className='ml-2 text-[12px] font-medium'
                >
                  I have read and agree to the{' '}
                  <Link
                    className='inline-block text-accent-blue hover:underline'
                    to='/terms-and-conditions'
                    target='_blank'
                  >
                    terms and conditions
                  </Link>
                </label>
              </div>
            </div>
          )}
        </div>
        <div className='lg:w-1/2 my-4'>
          <OrderSummary
            rego={rego}
            vin={vin}
            state={state}
            vehicleTitle={vehicleTitle}
            ppsrDisabled={ppsrDisabled}
          />
        </div>
      </div>
    </div>
  );
}
